// authurl
export const SERVER = "https://crm-engine.proximaai.co/api/"
export const LoginUrl = 'auth/login'
export const RegisterUrl = 'auth/register'
export const ForgotPasswordUrl = 'auth/forgot-password'

export const AuthUrl = 'auth/google'
// org
export const OrgUrl = 'org'
// export const OrgUrl = 'auth/create-org'
// company

export const CompanyUrl = 'leads/company'
export const CompaniesUrl = 'leads/companies'
// Lead
export const LeadUrl = 'leads'
// Contact
export const ContactUrl = 'contacts'
// Opportunity
export const OpportunityUrl = 'opportunities'
// ACCOUNTS
export const AccountsUrl = 'accounts'
// CASES
export const CasesUrl = 'cases'
// USERS
export const UsersUrl = 'users'
export const UserUrl = 'user'
// PROFILE
export const ProfileUrl = 'profile'
// 
export const Header = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: localStorage.getItem('Token'),
    org: localStorage.getItem('org')
}
